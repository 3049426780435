.category-container {
    max-width: 1000px;
    width: 100%;
    .product-list {
        // width: 100%;
        // background-color: rgb(154, 200, 154);
        background-color: #eee;
        list-style: none;
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        padding: 20px;
        // gap: 10px;
        .list-item {
            // width: 300px;
            // height: 300px;
            // border: 1px solid #ccc;
            justify-self: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .collection-img{
                border-radius: 10px;
            }
        }
    }
}
