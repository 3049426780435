body {
    // font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .homepage {
    text-align: center;
  }
  
  .hero {
    position: relative;
    color: white;
  }
  
  .hero-image {
    width: 100%;
    height: auto;
  }
  
  .cta-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #ff5733;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .about-us, .featured-products, .testimonials, .blog, .contact{
    padding: 20px;
  }
  
  .product-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
  }
  
//   .product {
//     width: 30%;
//   }
  
  .product-img {
    width: 300px;
    height: 300px;
    flex-grow: initial;
    object-fit: contain;
  }
//   .contact-form {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
  
//   .contact-form label {
//     margin: 10px 0;
//   }
  
//   .contact-details, .map {
//     margin-top: 20px;
//   }
  
//   .footer-links, .social-media {
//     display: flex;
//     justify-content: space-around;
//     margin-top: 20px;
//   }
  
  .grid-2-1fr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    // max-width: 1400px;
    width: 100%;
    gap:100px;
  }

  .blog-posts {
    flex-direction: column;
    max-width: 800px;
    width: 100%;

    .post-article {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 10px;
    }
  }

  blockquote {
    cite {
        font-weight: 600;
    }
  }

@media screen and (max-width: 600px) {
    .grid-2-1fr {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

@media screen and (max-width: 400px) {
    .grid-2-1fr {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .product-gallery {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .product-img {
        width: 250px;
        height: 250px;
    }
}
  