.cell-partitioned-table{
    border: 1px solid #888;
    // width: 50%;

    th,td{
        border: 1px solid #888;
    }

    border-collapse: collapse;
}

.warning-notice{
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center; 
    max-width:100%; 
    // height: 120px;
    background:rgb(33, 33, 33);
    border-radius: 15px; 
    // color:red;
    .warning-notice-title{
        height:100%; 
        // background:rgba(255,0,0,0.5); 
        // width:40%;
        // border-bottom: 5px solid coral;
        border-top-left-radius:15px;
        border-bottom-left-radius:15px; 
        display:flex; 
        justify-content:center;
        align-items:center;
        
        color: coral;
        font-size: 1.1rem;
        font-weight: 600;
    }
    .warning-notice-msg{
        // width: 80%;
        padding: 10px 20px;
        color: white;
    }
}