#verify-otp, #submit-phone, .feedback-button, .outline-button{
    background-color: #1c271d;
    // border-color: #1c271d;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 1.1rem;
    padding: 10px 40px;
    margin-top: 30px;
    cursor: pointer;
  
    &:disabled{
      background: #888;
      color: #bbb;
      border: none;
      cursor: not-allowed;
    }
    &:active{
      background:#3c533d;
    }
  }


.outline-button{
    background: none;
    border: 2px solid #1c271d;
    color: #1c271d;
  
    &:hover{
      background: #1c271d;
      color: white;
    }
    &:active{
      background: white;
      color: #1c271d;
    }
  }