.flex-cc, .flex-sac, .flex-sbc{
    display: flex;
    align-items: center;
}
.flex-sbc{ justify-content: space-between;}
.flex-sac{ justify-content: space-around;}
.flex-cc{ justify-content: center;}
.flex-col{ flex-direction: column;}

// Dimentions- Width(w), Height(h)
.w-full{ width: 100%;}
.h-full{ height: 100%;}

// Font size
.fs-11{ font-size: 1.1rem;}

// Padding
.pa-10{ padding: 10px;}

// Margin
.mb-15{ margin-bottom: 15px;}
.mb-20{ margin-bottom: 20px;}

// border-radius
.br-5{ border-radius: 5px;}
.br-10{ border-radius: 10px;}

// border
.b-none{ border: none;}

// Input
.input-style{
    border: none;
    border-bottom: 1px solid #ccc;
}