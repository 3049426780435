.background-banner{
    background-image: linear-gradient(-45deg, rgba(0,172,1, 0.4), #24422a, #c6e7cc);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;

	// display: grid;
	// grid-template-columns: repeat(auto-fill, minmax(max-content, 300px));
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.flexible-grid{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(max-content, 1fr));
}