.adhesive-table{
    padding-top: 50px;
    border-collapse: collapse;
    border: 1px solid black;
    .table-head{
        background-color: #222e22;
        color: white;
        th{
            padding: 10px 5px;        
        }
    }
    td{
        padding: 10px 5px;    
    }
    tr:nth-child(even){
        background-color: #f2f2f2
    }
}

.font-bold {
    font-weight:600;
}