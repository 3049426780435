.footer-main{
    // position: sticky;
    // bottom: 0;
    margin-top: 150px;
    // background: rgb(35, 35, 35);
    background-color: #1c271d;
    padding: 50px 0px 20px 0px;
    .footer-options{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 50px;
        margin: 0px 50px;
        padding-bottom: 20px;
        a, .footer-text, .footer-heads{
            padding-bottom: 5px;
            text-decoration: none;
            color: rgb(200, 200, 200);
            font-size: 0.9em;

            &:hover{
                text-decoration: underline;
            }
        }
        .footer-heads{
            color:rgb(130, 130, 130);
            font-size: 0.8rem;
            &:hover{
                text-decoration: none;
            }
        }
        .footer-text{
           font-size: 0.8rem; 
           &:hover{
               text-decoration: none;
           }
        }
    }

}
.copyright{
    margin-top: 10px;
    border-top: 1px solid rgb(55, 55, 55);
    padding-top: 10px;
    margin-bottom: 0;
    font-size: 0.8em;
    color: rgb(150, 150, 150);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.PaymentLogo{
    display: block;
    padding: 5px;
    opacity: 0.7;
}
.PaymentsCheckoutForm__paymentMethods{
    transform: scale(0.5);
    align-items: center;
    align-self: center;
    display: flex;
}

@media only screen and (max-width: 1050px){
    .footer-main{
        .footer-options{
            grid-template-columns: 1fr 1fr;
            gap: 50px;
        }
    }
    .copyright{
        transform: scale(0.8);
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 450px){
    .footer-main{
        .footer-options{
            grid-template-columns: 1fr;
            // gap: 50px;
            margin: 0px 20px;
        }
    }
    .copyright{
        transform: scale(0.8);
        flex-direction: column-reverse;
    }
}