.header-top{
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    // padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -2px 10px grey;
    // background-color: white;
    z-index: 2;
    // background-image: linear-gradient(45deg, white, rgb(111, 146, 58));
    backdrop-filter: blur(11px) saturate(200%);
    -webkit-backdrop-filter: blur(11px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.56);
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    nav{
        margin-left: auto;
    }
}

.down{
    transform: rotate(180deg);
    transition: 0.3s;
}

.up{
    transform: rotate(0deg);
    transition: 0.3s;
}
.nav-link{
    margin: 0px 20px;
    color:#333;
    text-decoration: none;
}
.hover-underline{
    &:hover{
        text-decoration: underline;
    }
}

#header-menu{
    display: none;
}

#header-text{
    padding-left: 20px;
    display: grid;
    grid-template-columns: 1fr;

    #header-text-subtitle{
        font-size: 0.6rem;
        font-style: italic;
        text-align: center;
    }
}

.hidden{
    display: none;
}

.header-slider{
    position: fixed;
    width: 100vw;
    left: 0px;
    top: -20px;
    height: 30vh;
    padding-top: 80px;
    padding-bottom: 40px;
    z-index: -1;
    background-color: white;
    // background-color: pink;
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    color: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 10px grey;
}
.header-nav{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 120px;
    color: grey;
}
.pm-12{
    padding: 6px 24px;
    margin: 12px;
}

#header-logo{
    width: 4.5rem;
    // margin-left: 120px ;
}

.pointer{
    cursor: pointer;
}

.bottom-bar{
    border: 2px solid white;
    border-top: none;
    border-left: none;
    border-right: none;
    &:hover{
        border-bottom-color: rgb(111, 146, 58);
    }
}

.login{
    border: 2px solid grey;
    border-radius: 8px;
    &:hover{
        background-color: black;
        color: white;
        border-color: black;
    }
    &:active{
        background-color: black;
        border-color: black;
        color: white;
    }
}

.flex-start{
    display: flex;
    padding:0 10px;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgb(75, 75, 75);
}

.click-animation:active{
    transform: scale(0.95);
}

.linksToRoute{
    text-decoration: none;
    color: inherit;
}

.searchBox{
    padding: 5px 15px ;
    border: 2px solid white;
    box-shadow: 0 0 10px grey;
    border-radius: 3px;
    font-size: 1.1rem;
    width: 350px;
    background: none;
    border-radius: 25px;
}

@media only screen and (max-width: 1050px){
    .flex-start{
        font-size: 1.2rem;
    }
    #header-logo{
        width:3rem;
        padding-left: 0;
    }
    #header-menu{
        display: inline-flex;
        margin-right: 30px;
        margin-left: 30px;
        padding: 0;
        font-size: 0.8rem;
    }
    .header-nav{
        display: none;
    }
    .searchBox{
        width: 250px;
        margin: 0;
    }
}