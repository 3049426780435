.full-screen{
    width: 100vw;
    height: 100vh;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-div{
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .loading-circle{
        width: 150px;
        height: 150px;
        
        border-radius: 100px;
        // border-top-left-radius: 0;
        // background: white;
        border-left: 5px solid grey;
        animation: rotate 1s linear 0.2s infinite forwards ;
    }
}

@keyframes rotate{
    0% {transform: rotate(0);}
    50% {border-color: #223024}
    100% {transform: rotate(360deg);}
}


.loading-circle{
    position: relative;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .inner-circle{
        position: absolute;
        background-color: #223024;
        background: none;
        // background: rgb(52, 86, 185);
        width: 70%;
        height: 70%;
        border-radius: 10px;
    }
    .rotary-circle{
        position: absolute;
        // background: white;
        width: 85%;
        height: 85%;
        border-left: 1px solid #223024;
        border-radius: 10px;
    }
    .rotate{
        animation: rotate-rotary-circle 0.5s linear infinite 0s forwards;
    }
    @keyframes rotate-rotary-circle{
        0%{transform:rotate(0deg);}
        100%{transform:rotate(360deg);}
    }
}