.flexible-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 800px) {
    .flexible-div{
        // display: none;
        flex-direction: column;
    }
}