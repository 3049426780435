.carousel {
    position: relative;
    width: 100%;
    height: 100%;
    // background-color: pink;
    // overflow: hidden;
    min-height: 100px;
  }
  
  .slide {
    // opacity: 0%;
    display: none;
    width: 100%;
    height: 100%;
    padding: 20px 0px;
  }
  
  .slide.active {
    display: block;
    // opacity: 100%;
  }
  
  .button-carousel {
    position: absolute;
    bottom: 0;
    transform: translateY(-50%);
  }
  
  .button-carousel:first-of-type {
    left: 0;
  }
  
  .button-carousel:last-of-type {
    right: 0;
  }
  
  .indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
  }
  
  .indicator, .indicator-play-pause {
    width: 10px;
    height: 10px;
    background-color: grey;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .indicator-play-pause {
   padding:5px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: white;
//    background-color: #c6e7cc;
  }
  .indicator.active {
    background-color: black;
  }
  