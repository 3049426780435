.action-button{
    background:#24422a; 
    color:white; 
    padding:10px 25px; 
    border:3px solid #c6e7cc; 
    border-radius:5px; 
    font-size:1.05rem; 
    cursor:pointer;
    text-decoration:none;
}

.primary-button{
    background:orange; 
    color:white; 
    padding:10px 25px; 
    border:3px solid #dbccb2; 
    border-radius:5px; 
    font-size:1.05rem; 
    cursor:pointer;
    text-decoration:none;
}

.outline-button, .light-button{
    background:none; 
    color:#24422a; 
    padding:10px 25px; 
    border:3px solid #c6e7cc; 
    border-radius:5px; 
    font-size:1.05rem; 
    cursor:pointer;
    text-decoration:underline;
}

.light-button{
    background:white;
    text-decoration: none;
    &:hover, &:focus{
        background-color: #c6e7cc;
    }
    &:active{
        background-color: #8dab93;
    }
}

.text-button{
    background:none; 
    color:inherit; 
    padding:10px 25px; 
    border:none; 
    border-radius:50px; 
    font-size:1.05rem; 
    cursor:pointer;
    text-decoration:underline;
}

.survey-button{
    width:45px; 
    height:45px; 
    background:none; 
    border:3px solid #eee;
    border-radius:10px; 
    cursor:pointer;
}
.hover-green{
    &:hover, &:focus{
        background-color: #c6e7cc;
        border-color: #c6e7cc;
    }
}
.hover-red{
    &:hover, &:focus{
        background-color: #e8d1d1;
        border-color: #e8d1d1;
    }
}